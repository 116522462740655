import React, { useState } from 'react';
import { VStack, Select, Textarea, Button, Text } from '@chakra-ui/react'; // Assuming you are using Chakra UI
import axios from 'axios';
import { Title } from '../../../components/atoms/Title';
import ReactInputMask from "react-input-mask";

const Input = (props) => (
  <VStack width="100%" spacing={3} alignItems="flex-start">
      <Title>{props.name}</Title>
      <ReactInputMask
          style={{
              backgroundColor: "#FAFAFA",
              width: "100%",
              padding: "1rem",
              border: "1px solid rgb(226, 232, 240)",
              borderRadius: "6px",
              height: "40px",
              ...props.style
          }}
          value={props.value}
          onChange={(e) => {
              props.onChange(e);
          }}
          name={props.name}
          mask={props.mask}
          placeholder={`Ex.: ${props.placeholder}`}
      />
  </VStack>
);

export const ContactForm: React.FC = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [about, setAbout] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [sent, setSent] = useState(false);

  const errorStyle = { border: '1px solid red' };
  const primaryColor = '#3182ce'; // Example primary color
  const primaryFontColor = '#fff'; // Example primary font color

  const onSend = () => {
    let errors = [];
    if (name === "") errors.push("name");
    if (email === "" || email.indexOf("@") === -1) errors.push("email");
    if (about === "") errors.push("about");
    if (message === "") errors.push("message");
    if (phone === "") errors.push("phone");

    if (errors.length > 0) return setErrors(errors);

    axios.post("http://api.partiu.com.br/api/v1/contact/", {
            name,
            email,
            subject: about,
            message,
            cellphone: phone,
            landing: "RevistaPartiu",
            origin: "landing"
        })
        .then(() => {
          setSent(true);
        });
  };

  return (
    <VStack maxWidth="600px" pX={4}>
      <Text marginBottom="48px" fontSize="24px" textAlign="center">
        Entre em contato com nosso time para mais informações sobre a revista
      </Text>
      {!sent ? (
        <VStack spacing={6}>
          <Input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrors([""]);
            }}
            name="Nome"
            placeholder="Joao da Silva"
            style={errors.indexOf("name") !== -1 ? errorStyle : {}}
          />
          <Input
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setErrors([]);
            }}
            name="Celular"
            placeholder="(xx) xxxx-xxxxx"
            mask="(99) 9 9999-9999"
            style={errors.indexOf("phone") !== -1 ? errorStyle : {}}
          />
          <Input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrors([]);
            }}
            name="Email"
            placeholder="joao@gmail.com"
            style={errors.indexOf("email") !== -1 ? errorStyle : {}}
          />
          <VStack width="100%" spacing={3} alignItems="flex-start">
            <Title>Assunto</Title>
            <Select
              value={about}
              onChange={(e) => {
                setAbout(e.target.value);
                setErrors([]);
              }}
              name="Assunto"
              backgroundColor="#FAFAFA"
            >
              <option value="Dúvidas">Dúvidas</option>
              <option value="Tenho interesse em cadastrar meu estabelecimento">
                Tenho interesse em cadastrar meu estabelecimento
              </option>
              <option value="Quero oferecer para meus clientes/funcionarios/associados">
                Quero oferecer para meus
                clientes/funcionarios/associados
              </option>
              <option value="Reclamação">Reclamação</option>
            </Select>
          </VStack>
          <Textarea
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              setErrors([]);
            }}
            name="Mensagem"
            placeholder="Tenho interesse em cadastrar meu estabelecimento"
            style={{
              ...(errors.indexOf("message") !== -1
                ? errorStyle
                : { minHeight: "150px" })
            }}
          />
          <Button
            isFullWidth
            onClick={() => onSend()}
            className="fly"
            color="white"
            height="45px"
            bgColor="#fc195a"
            h="60px"
            p="10px"
            whiteSpace="normal"
            text-align="center"
            fontWeight="semibold"
            lineHeight="20px"
            fontSize={{ base: "15px", lg: "17px" }}
            borderRadius="lg"
            _hover={{
                boxShadow: "0 10px 10px rgb(0 0 0 / 20%)",
                transform: "translateY(-5px)",
            }}
          >
            Enviar
          </Button>
        </VStack>
      ) : (
        <div
          className="contact-form"
          style={{
            height: "65vh",
            textAlign: "left",
            paddingTop: 30
          }}
        >
          <h1>Obrigado!</h1>
          <p>
            Mensagem enviada com sucesso, rapidinho entraremos em
            contato com você.
          </p>
        </div>
      )}
    </VStack>
  );
};

export default ContactForm;