import { Box, Button, Heading, HStack, Image, Link, Stack, Text, VStack, Flex } from "@chakra-ui/react";
import { FaCheck, FaDownload } from "react-icons/fa";
import { Footer } from "../../components/organisms/Footer";
import { NavBar } from "../../components/organisms/Header";
import { useCheckMobile } from "../../utils/CheckMobile";
import React from "react";
import { numberToBRL } from "../../utils/CurrencyToBr";
import { planDetail } from "./plan";
import { Divisor } from "../../components/atoms/Divider";

interface IPlanCardProps{
    title: string;
    items: String[];
    url: string;
    amount: number;
}

const PlanCard: React.FC<IPlanCardProps> = ({title, items, url, amount}) => {
    return (
        <Box bgColor={"white"} padding="24px" border="2px" width={{base: '100%', lg: '25%'}} borderRadius={"10px"} borderColor={"#ddd"} height="fit-content">
            <VStack>
                <Text fontSize={"24px"} textAlign={"center"} fontWeight="bold">{title}</Text>
                <Text fontSize={"48px"} textAlign={"center"} fontWeight="bold">{numberToBRL(amount)}</Text>
                <VStack spacing={5}>
                    {items.map(item => (
                        <HStack>
                            <FaCheck size="10pt" color="green" />
                            <Text width="220px" fontSize="11pt" lineHeight="1.35rem">
                                {item}
                            </Text>
                        </HStack>
                    ))}
                </VStack>
                <Button href={url} marginY={6} as={Link} textDecoration="none">
                    Contratar
                </Button> 
            </VStack>
        </Box>
    )
}

const Revista = () => {
    const isMobile = useCheckMobile();

    return (
        <VStack
            alignItems="center"
            justifyContent="center"
            w="100%"
            spacing={0}
            bgColo={"#e2e2e2"}
        >
            <NavBar />
            <Stack
                width="100%"
            >
                <Stack
                    direction={{base: "column", lg:"row"}}
                    bgGradient="linear(to-t, #D82B60, #FFA800)"
                    paddingY={{base: "30px", lg:"100px !important"}}
                    width="100%"
                    alignItems="center"
                    id="top"
                >
                    <Flex
                        pl={{ base: "0%", lg: "10%" }}
                        w={{ base: "90%", lg: "50%" }}
                        margin={{ base: "auto", lg: "initial" }}
                        mt={{ base: "150px", lg: "initial" }}
                        pt={{ base: "0px", lg: "80px" }}
                        textAlign="left"
                        direction="column"
                        justify="center"
                        // textShadow={{
                        //     base: "0px 0px 10px rgba(0,0,0,0.8)",
                        //     lg: "0px 0px 10px rgba(0,0,0,0.4)",
                        // }}
                        >
                        <Divisor />
                        <Heading
                            fontSize={{ base: "24pt", lg: "27pt" }}
                            fontFamily="Chivo"
                            fontWeight="bold"
                            color="white"
                        >
                            REVISTA PARTIU
                        </Heading>
                        <Heading
                            fontSize={{ base: "18pt", lg: "21pt" }}
                            fontFamily="Chivo"
                            fontWeight="medium"
                            color="white"
                            flexDirection="row"
                        >
                            todo mês uma nova edição com conteúdos exclusivos e descontos incríveis para você economizar.
                        </Heading>
                        <Button
                            as={Link}
                            href="#plans"
                            bgColor="white"
                            color="#D82B60"
                            fontWeight="bold"
                            fontSize="16pt"
                            padding="10px 30px"
                            marginY="20px"
                            marginX
                            width="fit-content"
                            _hover={{
                                bgColor: "#D82B60",
                                color: "white",
                                textDecoration: "none"
                            }}
                        >
                            Quero comprar
                        </Button>
                    </Flex>
                    <Image src={"https://static.partiu.com.br/revista-mockup-2.png "} maxHeight='600px' />
                </Stack>
                <Stack
                    direction="column"
                    justify={"center"}
                    alignItems={"center"}
                    maxWidth={"100%"}
                >
                    <Stack
                    direction="column"
                    justify={"center"}
                    alignItems={"center"}
                    maxWidth={"100%"}
                >
                    <Stack direction={{base: 'column', lg: 'row'}} alignItems="center" justifyContent="center" paddingY={{base: "16px", lg:"50px"}} paddingX={2}>
                        <Image
                            src={isMobile ? "https://static.partiu.com.br/banner-grande-mobile.png" : "https://static.partiu.com.br/banner-grande.png"}
                            maxWidth="100%"
                            maxHeight="920px"
                            border="1px solid #eee"
                        />
                        <Text fontSize="24px" maxWidth={"920px"} textAlign={"center"} marginX="auto" paddingY={"24px"} >
                            Que tal na mesma revista você acessar conteúdos
                            super legais como dicas de viagem, gastronomia,
                            pet entre outros e ainda ter acesso a um mundo de
                            vantagens todos os meses? Compre agora a Revista
                            Digital Partiu e aproveite!
                        </Text>
                    </Stack>
                    <Text fontSize="32px" maxWidth={"920px"} textAlign={"center"} marginX="auto" paddingY={"50px"} color="#505050">
                        Veja como é simples:
                    </Text>
                    <Image
                        src={isMobile? "https://static.partiu.com.br/banner-mobile-3.png" : "https://static.partiu.com.br/banner-passos-1.png"}
                    />
                </Stack>
                </Stack>
            </Stack>
            <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                paddingY={"24px"}
                width={{base: "90%", lg: "100%"}}
            >
                <Text fontSize="24px" maxWidth={"900px"} width="90%" paddingY={"24px"} textAlign={"center"}>
                    Encontre o plano perfeito para você e comece a economizar com descontos exclusivos! Escolha entre as opções Bronze, Prata ou Ouro.
                    <br/>
                    Aproveite todos os benefícios!
                </Text>
                <Stack id="plans">
                    
                    <Stack
                        direction={{ base: "column", lg: "row" }}
                        justifyContent={"space-around"}
                        alignItems={"center"}
                    >
                        <PlanCard 
                            title={planDetail.bronze.title}
                            items={planDetail.bronze.items}
                            amount={planDetail.bronze.amount}
                            url={planDetail.bronze.url}
                        />
                        <PlanCard 
                            title={planDetail.prata.title}
                            items={planDetail.prata.items}
                            amount={planDetail.prata.amount}
                            url={planDetail.prata.url}
                        />
                        <PlanCard 
                            title={planDetail.ouro.title}
                            items={planDetail.ouro.items}
                            amount={planDetail.ouro.amount}
                            url={planDetail.ouro.url}
                        />
                        <VStack paddingTop={{base: '16px', lg: '0'}}>
                            <Text fontSize="24px" fontWeight="bold">Edição Agosto</Text>
                            <Text>Selecione o plano e compre agora</Text>
                            <Image src="https://static.partiu.com.br/revista-mockup-so-capa.png" maxHeight="500px" />
                        </VStack>
                    </Stack>
                </Stack>

            </Stack>
            {/* <Stack
                direction="column"
                w="100%"
            >
                <Heading
                    color="#fc195a"
                    fontFamily="Chivo, sans-serif"
                    fontWeight="bold"
                    fontSize={isMobile ? "15pt" : "30pt"}
                    textAlign="center"
                    mb="40px"
                    mt={isMobile ? "0px" : "200pt"}
                >
                    OUTRAS EDIÇÕES
                </Heading>
                <Stack
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                    w="100%"
                    spacing="0"
                    flexWrap="wrap"
                    gap="10px"
                >
                    {
                        MockRevistaData.map(({datetime, downloadUrl}) => (
                            <Card
                                isMobile={isMobile}
                                datetime={datetime}
                                downloadUrl={downloadUrl}
                            />
                        ))
                    }
                </Stack>
            </Stack> */}
            <Footer />
        </VStack>
    )
}

export default Revista;