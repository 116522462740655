import { Flex, Heading, Image, Link, Stack, Text, VStack, Button } from "@chakra-ui/react";
import { Footer } from "../../components/organisms/Footer";
import { NavBar } from "../../components/organisms/Header";
import { useCheckMobile } from "../../utils/CheckMobile";
import { Divisor } from "../../components/atoms/Divider";
import { ContactForm } from "./components/contact-form";


export const MagazineForCompanies = () => {
    const isMobile = useCheckMobile();

    return (
        <VStack
            alignItems="center"
            justifyContent="center"
            w="100%"
            spacing={0}
            bgColo={"#e2e2e2"}
        >
            <NavBar />
            <Stack
                width="100%"
            >
                <Stack
                    direction={{base: "column", lg:"row"}}
                    bgGradient="linear(to-t, #D82B60, #FFA800)"
                    paddingY={{base: "30px", lg:"100px !important"}}
                    width="100%"
                    alignItems="center"
                    id="top"
                >
                    <Flex
                        pl={{ base: "0%", lg: "10%" }}
                        w={{ base: "90%", lg: "50%" }}
                        margin={{ base: "auto", lg: "initial" }}
                        mt={{ base: "150px", lg: "initial" }}
                        pt={{ base: "0px", lg: "80px" }}
                        textAlign="left"
                        direction="column"
                        justify="center"
                        // textShadow={{
                        //     base: "0px 0px 10px rgba(0,0,0,0.8)",
                        //     lg: "0px 0px 10px rgba(0,0,0,0.4)",
                        // }}
                        >
                        <Divisor />
                        <Heading
                            fontSize={{ base: "24pt", lg: "27pt" }}
                            fontFamily="Chivo"
                            fontWeight="bold"
                            color="white"
                        >
                            REVISTA PARTIU PARA EMPRESAS
                        </Heading>
                        <Heading
                            fontSize={{ base: "18pt", lg: "21pt" }}
                            fontFamily="Chivo"
                            fontWeight="medium"
                            color="white"
                            flexDirection="row"
                        >
                            quer comprar no atacado? Temos preços especiais para empresas e podemos personalizar os conteúdos com a identidade visual da forma que desejar.
                        </Heading>
                        <Button
                            as={Link}
                            href="#contact-button"
                            bgColor="white"
                            color="#D82B60"
                            fontWeight="bold"
                            fontSize="16pt"
                            padding="10px 30px"
                            marginY="20px"
                            marginX
                            width="fit-content"
                            _hover={{
                                bgColor: "#D82B60",
                                color: "white",
                                textDecoration: "none"
                            }}
                        >
                            Quero saber mais
                        </Button>
                    </Flex>
                    <Image src="https://static.partiu.com.br/revista-mockup-2.png " maxHeight='600px' />
                </Stack>
                <Stack
                    direction="column"
                    justify={"center"}
                    alignItems={"center"}
                    maxWidth={"100%"}
                >
                    <Text maxWidth={"920px"} textAlign={"center"} marginX="auto" paddingY={{lg: "100px", base: "32px"}} px={3}>
                        <b>Leve a experiência de leitura digital da sua marca para o próximo nível! </b>
                        <br/>
                        A Partiu constrói revistas personalizadas com identidade visual da sua empresa. Nela é possível personalizar os conteúdos. Já imaginou reportagem para seus funcionários, condôminos, associados ou clientes? É isso que podemos criar para você e muito mais.
                    </Text>
                    <Image
                        src={isMobile ? "https://static.partiu.com.br/banner-mobile-2.png" : "https://static.partiu.com.br/revista-banner-2.png"}
                    />
                    <Text maxWidth={"920px"} textAlign={"center"} marginX="auto" paddingY={{lg: "100px", base: "32px"}} px={3}>
                        Com essas ofertas exclusivas, seus clientes poderão aproveitar promoções especiais, garantindo economia significativa em cada compra. Além de fortalecer o vínculo com sua marca, você oferece a eles uma experiência de consumo mais vantajosa e acessível. Transforme a forma como seus clientes economizam e fidelize seu público com benefícios reais!
                    </Text>
                    <Image
                        src={isMobile ? "https://static.partiu.com.br/banner-mobile-3.png" : "https://static.partiu.com.br/banner-passos-2.png"}
                    />
                </Stack>
            </Stack>
            <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                spacing={0}
                w="100%"
                pt={isMobile ? "48px" : "200px"}
                px="16px"
                id="contact-button"
            >
                <ContactForm />
            </Stack>
            {/* <Stack
                direction="column"
                w="100%"
            >
                <Heading
                    color="#fc195a"
                    fontFamily="Chivo, sans-serif"
                    fontWeight="bold"
                    fontSize={isMobile ? "15pt" : "30pt"}
                    textAlign="center"
                    mb="40px"
                    mt={isMobile ? "0px" : "200pt"}
                >
                    OUTRAS EDIÇÕES
                </Heading>
                <Stack
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                    w="100%"
                    spacing="0"
                    flexWrap="wrap"
                    gap="10px"
                >
                    {
                        MockRevistaData.map(({datetime, downloadUrl}) => (
                            <Card
                                isMobile={isMobile}
                                datetime={datetime}
                                downloadUrl={downloadUrl}
                            />
                        ))
                    }
                </Stack>
            </Stack> */}
            <Footer />
        </VStack>
    )
}