import {
  Text,
  Flex,
  Heading,
  Box,
  Stack,
  Button,
  Grid,
  Spacer,
  useDisclosure,
  Menu,
  Center,
  MenuButton,
  MenuItem,
  MenuList,
  Link,
  Divider,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { MdExpandMore } from "react-icons/md";
import { useCheckMobile } from "../../utils/CheckMobile";
import Logo from "../atoms/Logo";

export const NavBar = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useCheckMobile();
  const bgVideo = props.bgVideo;

  const MobileMenu = () => {
    return (
      <Menu>
        <MenuButton
          position="absolute"
          left="10%"
          top="15%"
          as={IconButton}
          aria-label="Menu"
          icon={<AiOutlineMenu color="black" width="45px" fontSize="30px" />}
          backgroundColor="transparent"
        />
        <MenuList bgColor="gray.100" borderColor="transparent" color="black">
          {SubLinks.map((link, index) => {
            return (
              <Link key={index} href={link.url}>
                <MenuItem color="black">{link.title}</MenuItem>
              </Link>
            );
          })}
          <Link
            rel="noreferrer"
            href="https://app.partiu.com.br/contato"
            target="_blank"
          >
            <MenuItem color="black">Contato</MenuItem>
          </Link>          
        </MenuList>
      </Menu>
    );
  };

  const SubLinks = [
    {
      title: "Revistas",
      url: "/",
    },
    {
      title: "Para sua Empresa",
      url: "/para-sua-empresa",
    },
  ];

  const _Links = [
    {
      title: "Revistas",
      url: "/",
    },
    {
      title: "Para sua empresa",
      url: "/para-sua-empresa",
    },
    {
      title: "Contato",
      url: "/para-sua-empresa#contact-button",
    },
  ];

  return (
    <>
      {bgVideo ? (
        <Box position="absolute" left="0" top="0" h="100%">
          {props.bgVideo}
        </Box>
      ) : (
        <></>
      )}

      <Flex
        as="nav"
        zIndex="30"
        position="fixed"
        wrap="wrap"
        w="100%"
        justifyContent="center"
        align="center"
        bgColor="white"
        top="0px"
        boxShadow="0px 0px 20px rgba(0,0,0,0.1)"
      >
        <Flex
          w={{ base: "95%", lg: "95%" }}
          direction={{ base: "column", lg: "row" }}
          alignSelf="center"
          margin="auto"
          alignItems="center"
          h="100%"
          position="relative"
          justifyContent={{ base: "center", lg: "flex-start" }}
        >
          <Flex ml={{ base: "0px", lg: "150px" }} mt="10px" mb="10px">
            {isMobile ? (
              <Box justifySelf="flex-start">
                <MobileMenu />{" "}
              </Box>
            ) : (
              <></>
            )}
            <Link href="/">
              <Logo />
            </Link>
          </Flex>
          <Spacer />
          {/* <ParaEmpresas /> */}
          {!isMobile ? (
            <HStack spacing={10}>
              {_Links.map((link, index) => {
                return (
                  <Box key={index}>
                    <a
                      target={
                        link.url.indexOf("https") === -1 ? "_self" : "_blank"
                      }
                      rel="noreferrer"
                      href={link.url}
                    >
                      <Text
                        color="black"
                        fontSize="16px"
                        fontWeight="extrabold"
                        fontFamily="Chivo"
                      >
                        {link.title}
                      </Text>
                    </a>
                  </Box>
                );
              })}
            </HStack>
          ) : (
            <></>
          )}
        </Flex>
        <Spacer />
      </Flex>
    </>
  );
};
