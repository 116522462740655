import { Heading, HStack, Stack, Text, VStack } from "@chakra-ui/layout";
import { Link as ChakraLink, Divider, Image, Select } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Title } from "../atoms/Title";

function FooterSection({ title, children }) {
  return (
    <VStack flex="1" alignItems="flex-start">
      <Title
        textAlign="left"
        color="black"
        fontSize="16px"
        fontWeight="bold"
        marginBottom="10px"
      >
        {title}
      </Title>
      {children}
    </VStack>
  );
}

function FooterLink({ link, children }) {
  return (
    <ChakraLink
      style={{
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: "Raleway",
        textAlign: "left",
        color: "#555",
      }}
      href={link}
      target="_blank"
    >
      {children}
    </ChakraLink>
  );
}

function FooterSocialLink({ icon, link }) {
  return (
    <ChakraLink isExternal _hover={{ color: "#666" }} href={link}>
      <i style={{ fontSize: 42 }} className={icon}></i>
    </ChakraLink>
  );
}

export function Footer({}) {
  const [state, setState] = useState("Rio de Janeiro");

  const states = {
    "Rio de Janeiro": "Praça XV, 20, Edifício Bolsa Rio, Centro, Rio de Janeiro",
    "São Paulo - Capital":
      "Alameda Santos, 200 – Térreo - Bela Vista, São Paulo",
    "São Paulo - Santos": "Rua Dr. Acácio Nogueira, 06 - Boqueirão, Santos",
    Paraíba: "Av. Goiás, 705 - Estados, João Pessoa",
    Brasília: "QI 3 Bloco A Lotes N 04/36 Loja 6, Guará I – Distrito Federal",
    Piauí: "Av. Nossa Senhora de Fátima, 1613, Sala 2 – Teresina",
    Belém: "Rua Municipalidade, 985 - Umarizal",
    "Foz do Iguaçu": "Av. Juscelino Kubitschek, 1865 - Vila Paraguaia",
    Recife: "Av. República do Líbano, 251, Torre 3, Sala 1209 - Pina",
  };

  return (
    <VStack
      width="100%"
      padding="50px 0px"
      alignItems="flex-start"
      spacing={10}
      backgroundColor="white"
    >
      <Divider variant="solid" />
      <Stack
        width="85%"
        alignSelf="center"
        alignItems="flex-start"
        justifyContent="center"
        direction={{ base: "column", lg: "row" }}
        spacing={10}
      >
        <FooterSection title={`Clube Partiu`}>
          <FooterLink link="https://app.partiu.com.br/contato">Contato</FooterLink>
          <FooterLink link="https://app.partiu.com.br/rede">Parceiros</FooterLink>
          <FooterLink link="https://app.partiu.com.br/cadastrar-loja">
            Seja Parceiro (Estabelecimento)
          </FooterLink>
        </FooterSection>
        <FooterSection title="Contato">
          <Select
            size="md"
            variant="flushed"
            onChange={(v) => setState(v.target.value)}
          >
            {Object.keys(states).map((s) => (
              <option key={s} value={s}>
                {s}
              </option>
            ))}
          </Select>
          <FooterLink>{states[state]}</FooterLink>
          <FooterLink link="mailto:prevendas@partiu.com.br">
            Envie-nos um email (prevendas@partiu.com.br)
          </FooterLink>
          <FooterLink link="tel:21974204416">(21) 97420-4416</FooterLink>
        </FooterSection>
        <FooterSection title="Social">
          <HStack spacing={5}>
            <FooterSocialLink
              icon="fab fa-facebook"
              link="https://pt-br.facebook.com/partiuvantagens/"
            />
            <FooterSocialLink
              icon="fab fa-instagram"
              link="https://www.instagram.com/soupartiu/?hl=pt-br"
              backgroundColor="#FFA800"
            />
            <FooterSocialLink
              icon="fab fa-linkedin"
              link="https://www.linkedin.com/company/partiu-vantagens/"
            />
          </HStack>
        </FooterSection>
        <FooterSection title="Aplicativos">
          <HStack spacing={5}>
            <FooterSocialLink
              icon="fab fa-apple"
              link="https://itunes.apple.com/br/app/partiu-vantagens/id996531860?mt=8/"
            />
            <FooterSocialLink
              icon="fab fa-android"
              link="https://play.google.com/store/apps/details?id=br.com.partiuvantagens"
            />
          </HStack>
        </FooterSection>
      </Stack>
      <Divider />
      <Stack
        spacing={10}
        width="85%"
        alignSelf="center"
        direction={{ base: "column", lg: "row" }}
        justifyContent="space-between"
      >
        <HStack spacing={5}>
          <Image
            height="50px"
            src="https://partiu-static.s3.amazonaws.com/Logos/logo.png"
            alt="Logo da Partiu!"
          />
          <Text
            textAlign="left"
            maxWidth="120px"
            color="#555"
            fontSize="12px"
            fontWeight="600"
          >
            © Copyright 2021 - Partiu - Todos os direitos reservados
          </Text>
        </HStack>
        <HStack
          alignSelf="center"
          marginLeft="auto"
          pt={{ base: "-40px", lg: "40px" }}
          pb={{ base: "110px", lg: "0px" }}
        >
          <FooterLink link="https://app.partiu.com.br/legal/termos-de-uso">
            Termos de Uso
          </FooterLink>
          <Heading fontSize="14px">|</Heading>
          <FooterLink link="https://app.partiu.com.br/legal/politica-de-privacidade">
            Política de Privacidade
          </FooterLink>
        </HStack>
      </Stack>
    </VStack>
  );
}
